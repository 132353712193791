<template>
  <Dropzone
    :icon="icon"
    :progress="progress"
    :is-error="isError"
    :height="height"
    @click="filestack.picker(pickerOptions).open()"
    @on-drop="
      (files) => {
        uploadFile(files);
      }
    "
  >
    <slot :is-converting-to-pdf="isConvertingToPdf"></slot>
  </Dropzone>
</template>

<script setup lang="ts">
import type { PickerFileMetadata } from "filestack-js";
import type { SupabaseFile } from "~/utils/supabase.types";
import { fileColumns } from "~/utils/supabase.columns";
import type { Database } from "@asap/shared";
import { useFilestackClient } from "~/composables/useFilestack";
import MimeMatcher from "mime-matcher";

/**
 * See https://filestack.github.io/filestack-js/interfaces/PickerOptions.html#accept
 */
type AcceptedMimeType = string;

const props = defineProps<{
  accept: AcceptedMimeType[];
  maxFiles?: number;
  icon?: string;
  isError?: boolean;
  height?: string;
  convertToPdf?: boolean; // See https://www.filestack.com/docs/api/processing/#pdf-conversions
}>();

const emit = defineEmits<{ onUploadDone: [files: SupabaseFile[]]; onError: [files: File[]] }>();

const { client: filestack, definePickerOptions } = useFilestackClient();

const isConvertingToPdf = ref(false);

const { addToast } = useToast();

const supabase = useSupabase();

type FilestackFile =
  | PickerFileMetadata
  | {
      filename: string;
      handle: string;
      size: number;
      type: string;
      url: string;
      mimetype: string;
    };

type Source = Database["public"]["Enums"]["file_source"];

const progress = ref<number>();

const { convertToPdf: filestackConvertToPdf } = useFilestackConvertToPdf();

const insertFilestackIntoSupabase = async function (filestackFiles: FilestackFile[]): Promise<SupabaseFile[]> {
  let filestackFilesToUpload: FilestackFile[] = [];

  if (props.convertToPdf) {
    isConvertingToPdf.value = true;
    const transformedFilestackFiles = await Promise.all(
      filestackFiles.map(async (filestackFile) => {
        if (filestackFile.mimetype === "application/pdf") return filestackFile;
        else return await filestackConvertToPdf(filestackFile.handle);
      })
    );

    filestackFilesToUpload = transformedFilestackFiles;
  } else {
    filestackFilesToUpload = filestackFiles;
  }

  const { data: supabaseFiles, error: errorFiles } = await supabase
    .from("file")
    .insert(
      filestackFilesToUpload.map((filestackFile) => ({
        mimetype: filestackFile.mimetype,
        name: filestackFile.filename,
        source: "filestack" as Source,
        filestack_handle: filestackFile.handle,
        filestack_url: filestackFile.url,
      }))
    )
    .select(fileColumns);

  if (errorFiles) {
    addToast({
      type: "danger",
      title: "Erreur",
      description: errorFiles.message,
    });

    throw new Error(errorFiles.message);
  }

  isConvertingToPdf.value = false;

  return supabaseFiles;
};

const { handleFocusTrap } = useInjectFocusTrap();

const pickerOptions = definePickerOptions({
  maxFiles: props.maxFiles,
  accept: props.accept,
  onUploadDone: async ({ filesUploaded: filestackFiles }) => {
    // Show progress on the UI
    progress.value = 100;

    const supabaseFiles = await insertFilestackIntoSupabase(filestackFiles);
    emit("onUploadDone", supabaseFiles);

    // Reset progress
    progress.value = undefined;
  },
  onOpen: () => {
    handleFocusTrap(true);
  },
  onClose: () => {
    handleFocusTrap(false);
  },
});

const isAcceptedFile = function (file: File) {
  const matcher = new MimeMatcher(...props.accept);

  return matcher.match(file.type);
};

const uploadFile = async function (files: File[]) {
  let acceptedFiles: File[] = [];
  let rejectedFiles: File[] = [];

  files.forEach((file) => {
    if (isAcceptedFile(file)) acceptedFiles = [...acceptedFiles, file];
    else rejectedFiles = [...rejectedFiles, file];
  });

  if (rejectedFiles.length) {
    emit("onError", rejectedFiles);

    addToast({
      type: "danger",
      title: "Erreur",
      description: `Seul les fichiers de types ${props.accept.join(", ")} sont acceptés`,
    });
  }

  const filesToUpload = props.maxFiles ? acceptedFiles.slice(0, props.maxFiles) : acceptedFiles;

  if (!filesToUpload.length) return;

  const filestackFiles: FilestackFile[] = await filestack.value.multiupload(filesToUpload, {
    onProgress: (event) => {
      progress.value = event.totalPercent;
    },
  });

  const supabaseFiles = await insertFilestackIntoSupabase(filestackFiles);

  emit("onUploadDone", supabaseFiles);

  progress.value = undefined;
};
</script>
